import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LangueUpdatedChangedDTO } from 'common';

@Component({
    selector: 'hiji-import-traduction-modal-tpl',
    templateUrl: './import-traduction-modal-tpl.component.html',
    styleUrl: './import-traduction-modal-tpl.component.scss',
    standalone: false
})
export class ImportTraductionModalTplComponent {
  modal = inject(NgbActiveModal);

  langueUpdatedChanged: LangueUpdatedChangedDTO[];
}
